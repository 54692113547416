import React, {Component} from 'react';
import styled from "styled-components";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

import Colors from "../common/Colors";
import {Ul, Li,} from "../common/List"
import Day from "../common/Day";

import Clock from "../assets/images/clock_light.svg"
import Clipboard from "../assets/images/clipboard.svg"
import Hospital from "../assets/images/hospital.svg"
import UnreadIcon from "../common/UnreadIcon"

const P = styled.p`
  display: flex;
  padding: 0.2rem 0.5rem;
  flex-wrap: wrap;

  &::before {
    margin-right: 0.2rem;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    content: "";
    background-image: url(${props => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  & span {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
  }

  & div {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    padding-left: 1rem;
  }
`


const Ptitle = styled(P)`
  font-weight: bold;
  padding: 0;
  overflow: hidden;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &::before {
    width: 0rem;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

/**
 * 掲示板一覧
 */
class ThreadList extends Component {

    constructor(p) {
        super(p);
        dayjs.extend(customParseFormat)
    }

    selectTopic(facility_code, tid) {
        window.location.href = "/topics/" + facility_code + "/" + tid;
    }

    render() {
        const list = this.props.list;
        const threads = list.threads || []

        return (
            <Ul>
                {threads.map(t =>
                    (<Li key={t.tid} onClick={() => this.selectTopic(t.company.facility_code, t.tid)}>
                        <P src={Clock}>
                            <span>更新日：</span>
                            <span><Day parse={'YYYYMMDDTHHmmssZ'} format={'YYYY/MM/DD'}>{t.update_date}</Day></span>
                            <UnreadIcon isRead={!t.unread_count && t.Opened}
                                        visibility={!!t.unread_count || !t.Opened ? "visible" : "hidden"}/>
                        </P>
                        <P src={Clipboard}>
                            <span>管理番号：</span>
                            <span>{t.vid}</span>
                        </P>
                        <P src={Hospital}>{t.company.facility_name}</P>
                        <Ptitle>{t.subject}</Ptitle>
                    </Li>)
                )}
            </Ul>
        );
    }
}

export default ThreadList;
