import React, {Component} from "react";
import styled from "styled-components";
import Colors from "./Colors";
import ZIndex from "./ZIndex";
import Arrow from "../assets/images/arrow_up.svg"

const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.White};
  font-weight: bold;
  font-size: 1.5rem;
  background-color: ${Colors.TransBlack};
  border-radius: 4px;
  position: fixed;
  width: 3rem;
  height: 3rem;
  z-index: ${ZIndex.pageTop};
  right: 1rem;
  bottom: 0rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s linear 0s, bottom 0.3s linear 0s;
  background-image: url("${Arrow}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
`

export default class PageTop extends Component {

    render() {
        return (
            <Top id={'page_top'} onClick={this.scrollToTop}/>
        )
    }

    componentDidMount() {
        window.addEventListener('scroll', this.displayPageTop); // PropsにUpdater渡してあるので呼べる
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.displayPageTop); // Unmount時に外してあげる
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    displayPageTop = () => {
        let pos = Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop);
        if (0 == pos) {
            document.getElementById('page_top').style.opacity = '0'
            document.getElementById('page_top').style.bottom = '0rem'
        } else {
            document.getElementById('page_top').style.opacity = '1'
            document.getElementById('page_top').style.bottom = '1rem'
        }
    }
}