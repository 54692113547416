import styled from "styled-components";
import Colors from "../common/Colors";

export const CustomSelect = styled.label`
  display: inline;
  position: relative;
  padding: 0;
  margin: 0;
  width: ${props => props.width || "16rem"};

  &::after {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    font-size: 0.8rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    content: "\u25BC";
  }
`

export const SelectBox = styled.select`
  border: 1px solid ${Colors.border.primary};
  height: calc(2rem + 2px);
  padding: 0 0.5rem;
  width: ${props => props.width || "16rem"};
  border-radius: 4px;
  background-color: ${Colors.background.primary};
  appearance: none;

  &:invalid {
    color: ${Colors.Gray};
  }
`

export const Select = (props) => {
    return (
        <CustomSelect {...props}>
            <SelectBox {...props}/>
        </CustomSelect>
    )
}