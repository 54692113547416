import styled from "styled-components";
import Colors from "./Colors";

export const Ul = styled.ul`
  border: 4px ${Colors.background.secondary} solid;
  width: 100%;
`

export const Li = styled.li`
  padding: 1rem 0.5rem;

  &:nth-child(odd) {
    background-color: ${Colors.list.background.even};
  }

  cursor: pointer;
`
