import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

Amplify.configure({
    Auth: {
        region: "ap-northeast-1",
        userPoolId: process.env.REACT_APP_AMP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AMP_USER_POOL_CLIENT_ID,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});

export async function wsignOut() {
    const user = await Auth.signOut();
    //await Amplify.signOut({ global: true }); //all device
    console.log(user);
    return user;
}

export async function wtokenRefresh(old_token) {
    let response;
    try {
        response = await Auth.currentSession();
    } catch (e){
        console.log(e);
        return false;
    }
    
    if (!response || !response.isValid) return false;

    let new_token = response.getIdToken().getJwtToken();
    if(old_token !==new_token) localStorage.setItem("id_token", new_token);
    return new_token;
}

