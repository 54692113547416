import React, {Component} from 'react';


/**
 * ヘルプ画面
 */
class Help extends Component {

    render() {
        return (
            <h2 className="Title">ヘルプ</h2>
        );
    }

}


export default Help;
