import styled from "styled-components";
import Colors from "./Colors";

export const Table = styled.table`
  border: 4px ${Colors.background.secondary} solid;
  width: 100%;
`

export const Th = styled.th`
  text-align: left;
  vertical-align: middle;
  padding: 0.5rem 1rem;
  background-color: ${Colors.White};
  color: ${Colors.Gray};
`

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: ${Colors.list.background.even};
  }

  &:hover {
    background-color: ${props => props.clickable ? Colors.list.background.hover : undefined};
  }

  & Td {
    cursor: ${props => props.clickable ? "pointer" : undefined};
  }
`

export const Td = styled.td`
  padding: 1rem;
`