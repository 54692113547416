import React, {Component} from 'react';
import styled from 'styled-components';
import WelbyIconSrc from '../assets/images/Welby.svg'
import TitleImage from "../assets/images/title.png"
import RingIconSrc from '../assets/images/ring.svg'
import Navbar from './Navbar.js'
import NavBurger from "./NavBurger";
import Colors from "./Colors";
import MediaQuery, {LargeScreen, SmallScreen, isLargeScreen, isSmallScreen} from "./MediaQuery";
import ZIndex from "./ZIndex";
import State from "./State";

const WelbyHeader = styled.header`
  z-index: ${ZIndex.header};
  height: 4rem;
  border-bottom: solid 4px ${Colors.border.secondary};
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: ${MediaQuery.largeScreen.width};
  background-color: ${Colors.background.primary};
  padding: 0 calc((100% - ${MediaQuery.largeScreen.width}) / 2);

  @media (max-width: ${MediaQuery.largeScreen.width}) {
    width: 100%;
  }
`

const Title = styled.h1`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 1rem;
  padding-left: 1rem;
  cursor: pointer;
`

const ServiceTitle = styled.span`
  width: 9rem;
  font-size: 1rem;
  color: #888;
`

const IconImage = styled.img`
  height: 1.5rem;
  margin: auto 0;
`

const WelbyIconImage = styled(IconImage)`
  margin: 0 0.5rem 0 0;
`

const RingIconImage = styled(IconImage)`
  margin: 0rem 0.2rem 0.4rem;
  vertical-align: middle;
`

const Welby = styled.h1`
  color: ${Colors.Orange};
  font-weight: bold;
  margin-right: 0.5rem;
  //font-family: Zapfino;
`

/**
 * ヘッダー
 */
export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLarge: isLargeScreen,
        }
    }

    componentDidMount() {
        window.addEventListener('storage', this.storageChange)
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.storageChange)
    }

    storageChange(event) {
        if (!!event.oldValue && !!event.newValue) {
            location.href = "/";
        }

    }


    render() {
        return (
            <WelbyHeader id={"header"}>
                <Title onClick={() => window.location.href = "/"}>
                    <img src={TitleImage} width={'217'}/>
                    {/*<WelbyIcon />*/}
                    {/*<ServiceTitle>*/}
                    {/*    PRO<RingIcon />Connect*/}
                    {/*</ServiceTitle>*/}
                </Title>
                {State.LoggedIn &&
                <LargeScreen>
                    <Navbar/>
                </LargeScreen>
                }
                {State.LoggedIn &&
                <SmallScreen>
                    <NavBurger/>
                </SmallScreen>
                }
            </WelbyHeader>
        );
    }
}

class WelbyIcon extends Component {
    render() {
        return (<Welby>Welby</Welby>)
        // return (
        //     <WelbyIconImage src={WelbyIconSrc} alt="Welby" />
        // );
    }
}

class RingIcon extends Component {
    render() {
        return (
            <RingIconImage src={RingIconSrc} alt="Welby"/>
        );
    }
}