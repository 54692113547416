import dayjs from "dayjs";
import {Component} from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ja from 'dayjs/locale/ja'

const Dayjs = dayjs.extend(customParseFormat);

export default class Day extends Component {
    constructor(props) {
        super(props);
        Dayjs.locale(props.locale || ja)
    }

    render() {
        return (
            <time dateTime={this.props.children}>
                {Dayjs(this.props.children, this.props.parse).format(this.props.format)}
            </time>
        )
    }
}