import { wsignOut, wtokenRefresh } from "./Auth";
import State from "../common/State";

const UNAUTHORIZED = 401
const FORBIDDEN = 403
const  INTERNAL_SERVER_ERROR = 500

export default class MessageIntervention {
    constructor() {
        // if (location.search != null && location.search.length > 0) {
        //     let params = new URLSearchParams(location.search);
        //     let idToken = params.get("id_token");
        //     if (idToken != null && idToken.length > 0) {
        //         localStorage.setItem("id_token", idToken);
        //     }
        //     let facilitycode = params.get('facility_code');
        //     if (facilitycode != null && facilitycode.length > 0) {
        //         localStorage.setItem("facility_code", facilitycode);
        //     }
        //     let session = params.get('session');
        //     if (session != null && session.length > 0) {
        //         localStorage.setItem("token", session);
        //     }
        // }
        this._id_token = localStorage.getItem("id_token");
        this._facility_code = localStorage.getItem("facility_code");
        this._token = localStorage.getItem("token");
        this._maxMsgLen = 2000;
        this._maxSubLen = 100;
        this.BACKEND_API_ENDPOINT_BASEURL = process.env.REACT_APP_BACKEND_API_ENDPOINT_BASEURL;
        this.loadingTimer = null;
        this.loadingLoop = 0;
        window.addEventListener('message', event => {
            if (event.origin.indexOf('welby.jp') > 0) {
                if (event.data == "show") {
                    this.loadingShow();
                } else if (event.data == "close") {
                    this.loadingClose();
                }
            }
        });
    }

    setHeader(json) {
        if (json.x_jp_welby_session_hash != null && json.x_jp_welby_session_hash.length > 0) {
            localStorage.setItem("token", json.x_jp_welby_session_hash);
            this._token = localStorage.getItem("token");
        }
        if (json.x_jp_welby_session_refere != null && json.x_jp_welby_session_refere.length > 0) {
            localStorage.setItem("token_old", json.x_jp_welby_session_refere);
        }
    }

    async checkLogout(response) {
        if (!response.ok&&location.pathname != "/logout") {
            let json = await response.json();
            if (response.status === FORBIDDEN && json.message.includes("Initial setting not complete.")) {
                if (location.pathname == "/profile") {
                    return;
                }
                location.href = "/profile";
                return;
            }

            if (response.status === UNAUTHORIZED || response.status === FORBIDDEN) location.href = "/logout";
            if (response.status === INTERNAL_SERVER_ERROR && json.message.includes("Cognito")) location.href = "/logout";
            
            if (!State.IsItialized) {
                localStorage.setItem("is_initialized",true)
                State.IsItialized = true
            }
        }
    }

    async tokenRefresh() {
        let newtoken = await wtokenRefresh(this._id_token);
        if (!newtoken&&location.pathname != "/logout") location.href = "/logout";

        return newtoken;
    }

    loadingClose() {
        if (this.loadingTimer != null) {
            clearTimeout(this.loadingTimer);
        }
        this.loadingTimer = null;
        this.loadingLoop = 0;
        let target = window.document.getElementById("loading");
        if (target != null) {
            target.style.display = "none";
        }
        return;
    }

    loadingShow() {
        if (this.loadingTimer != null) {
            clearTimeout(this.loadingTimer);
        }
        this.loadingTimer = null;
        let target = window.document.getElementById("loading");
        if (target != null) {
            this.loadingLoop = 0;
            target.style.display = "block";
            this.loadingTimer = setTimeout(this.loadingClose, 30000);
        } else {
            if (this.loadingLoop < 5) { // レンダリング1秒間待機
                this.loadingLoop += 1;
                this.loadingTimer = setTimeout(this.loadingShow, 200);
            }
        }
        return;
    }

    async wlp_list_thread(id_token, token, facility_code, index, descendin, size) {
        var data = "companyid=" + facility_code + "&index=" + index + "&size=" + size + "&descending=" + descendin;
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/thread/list", {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;    
    }

    async wlp_list_threadV2(id_token, token, facility_code, word, status, index, descendin, size) {
        var data = "companyid=" + facility_code + "&index=" + index + "&size=" + size + "&descending=" + descendin + "&word=" + word + "&status=" + status;
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/thread/list", {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_search_thread(id_token, token, facility_code, word, descendin, size) {
        var data = "companyid=" + facility_code + "&size=" + size + "&descending=" + descendin + "&word=" + word;
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/thread/search", {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_search_threadV2(id_token, token, facility_code, word, descendin, status, size) {
        var data = "companyid=" + facility_code + "&size=" + size + "&descending=" + descendin + "&word=" + word + "&status=" + status;
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/thread/search", {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_detail_thread(id_token, token, facility_code, tid, index, descendin, size) {
        var data = "companyid=" + facility_code + "&size=" + size + "&descending=" + descendin + "&index=" + index + "&threadid=" + tid;
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/thread?" + data, {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_add_thread(id_token, token, facility_code, subject, message, size) {
        return "not available"
    }

    async wlp_mod_thread(id_token, token, facility_code, tid, subject, message, size) {
        return "not available"
    }

    async wlp_del_thread(id_token, token, facility_code, tid, size) {
        return "not available"
    }

    async wlp_add_comment(id_token, token, facility_code, doctorid, tid, message, index, descendin, size) {
        var data = "companyid=" + facility_code + "&size=" + size + "&descending=" + descendin + "&message=" + message + "&threadid=" + tid + "&index=" + index + "&doctorid=" + doctorid + "&messageMaxLength=" + this._maxMsgLen;
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/comment", {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_mod_comment(id_token, token, facility_code, tid, cid, message, index, descendin, size) {
        var data = "companyid=" + facility_code + "&size=" + size + "&descending=" + descendin + "&message=" + message + "&threadid=" + tid + "&index=" + index + "&commentid=" + cid + "&messageMaxLength=" + this._maxMsgLen;
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/comment", {
            method: 'PUT',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_del_comment(id_token, token, facility_code, tid, cid, index, descendin, size) {
        var data = "companyid=" + facility_code + "&size=" + size + "&descending=" + descendin + "&threadid=" + tid + "&index=" + index + "&commentid=" + cid;
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/comment?" + data, {
            method: 'DELETE',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_facility_list(id_token, token) {
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/companylist", {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_logout(id_token, token, url) {
        this.loadingShow();
        let newtoken = await wtokenRefresh(this._id_token);
        if (newtoken) {
            id_token = newtoken;
            const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/logout", {
                method: 'GET',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache',
                //credentials: 'same-origin',0
                headers: {
                    //   'Content-Type': 'application/json'
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'x-jp-welby-session-hash': token, //<-- あとでtoken
                    'Authorization': 'Bearer ' + id_token
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                // body: data
            })
        }
        this.loadingClose();
        await wsignOut();
        localStorage.clear();
        location.href = url;
    }

    async wlp_get_usagelimit_visible(id_token, token) {
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/usagelimit", {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_set_usagelimit_visible(id_token, token, visible) {
        var data = "visible=" + (visible ? "true" : "false");
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/usagelimit", {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: data
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }

    async wlp_get_unread(id_token, token) {
        this.loadingShow();
        let newtoken = await this.tokenRefresh();
        id_token = newtoken;
        const response = await fetch(this.BACKEND_API_ENDPOINT_BASEURL + "/Prod/v1/unread", {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
                //   'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-jp-welby-session-hash': token, //<-- あとでtoken
                'Authorization': 'Bearer ' + id_token
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
        this.loadingClose();
        await this.checkLogout(response);
        const json = await response.json();
        this.setHeader(json);
        return json;
    }
}
