import React, {Component} from "react";
import Config, {LoginURL} from "../config/Config"
import MessageIntervention from "../lib/MessageIntervention";

export default class Logout extends Component {
    render() {
        let MI_API = new MessageIntervention();
        MI_API.wlp_logout(MI_API._id_token, MI_API._token, "/")
            .then()
            .catch((error) => {
                sessionStorage.clear();
                localStorage.clear();
                location.href = "/";
            });
        return (
            <div>
                <h1>ログアウトします。</h1>
            </div>
        )
    }
}