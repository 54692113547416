import React, {Component} from "react";
import Config, {LoginURL, ProfileURL} from "../config/Config"
import Iframe from 'react-iframe'
import styled from "styled-components";


const FrameContainer = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
`
export default class Index extends Component {

    render() {
        return (
            <FrameContainer>
                <Iframe
                    display="block"
                    position='relative'
                    width='100%'
                    height='100%'
                    url={LoginURL}
                />
            </FrameContainer>
        )
    }
}