import React, {Component} from "react";
import styled from "styled-components";

import Colors from "./Colors";

import Home from "../assets/images/home.svg"
import User from "../assets/images/user.svg"
import Inquiry from "../assets/images/inquiry.svg"
import Mail from "../assets/images/mail.svg"
import Logout from "../assets/images/logout.svg"
import ZIndex from "./ZIndex";
import { ProfileURL } from "../config/Config";
import State from "./State";

const Nav = styled.nav`
  position: relative;
  text-align: center;
  width: 4rem;
  height: 100%;
`

const Hamburger = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: ${ZIndex.burger};

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${Colors.text.primary};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({open}) => open ? '0' : '1'};
      transform: ${({open}) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
}
`

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.background.overlay};
  display: ${props => props.visible ? 'flex' : 'none'};
  justify-content: flex-end;
`

const Ul = styled.ul`
  color: ${Colors.text.primary};
  display: flex;
  width: 80%;
  height: calc(100% - 10rem);
  padding: 5rem 1rem;
  background-color: ${Colors.background.primary};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const Li = styled.li`
  text-align: left;
  border-bottom: 1px solid ${Colors.border.secondary};
  padding: 1rem;
  width: 100%;

  &::before {
    margin-right: 1rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
    content: "";
    background-image: url(${props => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  & a {
    color: ${Colors.text.primary};
    text-decoration: none;
    cursor: pointer;
  }
`

const Ul2 = styled(Li).attrs(props => ({
    as: 'ul',
    title: props.title,
}))`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &::before {
    width: 100%;
    padding-left: 3rem;
    content: "${props => props.title}";
  }
`

const Li2 = styled(Li)`
  border: none;
  padding: 0.5rem 1rem;

  &::before {
    height: .8rem;
    margin: 0 0.5rem 0 0;
    color: ${Colors.primary};
    font-weight: bold;
    content: "\u25CF"; // ●
    font-size: .5rem;
    text-align: right;
    vertical-align: 1px;
  }
`

const Li3 = styled(Li)`
  display: ${props => props.visible ? 'flex' : 'none'};
`



export default class NavBurger extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: false,
        }
    }

    toggleBurger = (e) => {
        e.preventDefault();
        this.setState(state => {
            document.body.style.overflow = !state.isOpen ? 'hidden' : 'auto'
            document.documentElement.style.overflow = !state.isOpen ? 'hidden' : 'auto'
            return ({
                isOpen: !state.isOpen,
            })
        });
    }

    render() {
        return (
            <Nav>
                <Hamburger open={this.state.isOpen} onClick={this.toggleBurger}>
                    <div/>
                    <div/>
                    <div/>
                </Hamburger>
                <Menu visible={this.state.isOpen}>
                    <Ul>
                        <Li3 visible={State.IsItialized} src={Home}><a href="/topics">ホーム</a></Li3>
                        <Li3 visible={State.IsItialized} src={User}><a href="/profile">プロフィール</a></Li3>
                        <Ul2 src={Inquiry} title={"ヘルプ"}>
                            <Li2><a href="https://www.youtube.com/watch?v=qe70mr_I4Bc" target="_blank">使い方
                                (動画)</a></Li2>
                            {/*<Li2><a href="https://www.youtube.com/watch?v=gboTdMTQpdA" target="_blank">使い方2 (動画)</a></Li2>*/}
                            {/*<Li2><a href="https://www.youtube.com/watch?v=gboTdMTQpdA" target="_blank">使い方3 (動画)</a></Li2>*/}
                        </Ul2>
                        <Li src={Mail}><a href="https://welby.jp/contact/app" target="_blank">お問合わせ</a></Li>
                        <Li src={Logout}><a href="/logout">ログアウト</a></Li>
                    </Ul>
                </Menu>
            </Nav>
        )
    }
}