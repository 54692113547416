import React, {Component} from 'react';
import styled from "styled-components";
import Colors from "./Colors";
import ZIndex from "./ZIndex";

const Foot = styled.footer`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: ${Colors.White};
  background: ${Colors.Gray};
  z-index: ${ZIndex.footer};
  transform: translate3d(0, 0, 150px);
`

/**
 * フッター
 */
class Footer extends Component {
    render() {
        return (
            <Foot>
                <p>&#169;&nbsp;Welby, inc.</p>
            </Foot>
        );
    }
}


export default Footer;
