import React, {Component} from 'react';
import styled from 'styled-components'
import Colors from "../common/Colors";
import {PrimaryButton} from "../common/Button";
import MessageIntervention from "../lib/MessageIntervention";
import {Textarea} from "../form/Textarea";

const CommentForm = styled.form`
  width: calc(100% - 4rem);
  padding: 2rem;
  background-color: ${Colors.background.secondary};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
`

const CommentArea = styled(Textarea)`
  margin-bottom: 1rem;
  resize: vertical;
`

/**
 * 投稿フォーム
 */
class CommentAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            data: {
                facility_code: "",
                facility_name: "",
                index: 0,
                size: 0,
                length: 0,
                descending: true,
                tid: "",
                doctor_sub: "",
                patient_sub: "",
                subject: "",
                message: "",
                create_date: "",
                update_date: "",
                visible: true,
                deleted: false,
                comments: []
            }
        };
        this.mi = new MessageIntervention();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.state.comment) return;

        this.mi.wlp_add_comment(
            this.mi._id_token,
            this.mi._token,
            this.mi._facility_code,
            this.props.thread.doctor_sub,
            this.props.thread.tid,
            this.state.comment,
            this.props.thread.index, true, 100)
            .then(data => {
                let pattern = "文字数が上限を超えています";
                if (data.message && data.message.indexOf(pattern) > -1) {
                    console.error(
                        data.message,
                        '\n comment length:' + this.state.comment.length
                    )
                } else {
                    this.setState({
                        comment: '',
                        data: data
                    })
                }
            })
            .then(() => {
                this.props.onSubmit()
            })
            .catch(error => console.error(error))
    }

    handleChange = (e) => {
        this.setState({comment: e.target.value});
    }


    render() {
        return (
            <CommentForm onSubmit={this.handleSubmit}>
                <CommentArea
                    name="comment"
                    maxLength="2000"
                    value={this.state.comment}
                    onChange={this.handleChange}
                    placeholder={"コメントを入力してください（2,000文字まで）"}
                />
                <PrimaryButton type="submit" disabled={!!!this.state.comment || this.props.commentEditing}>コメントを追加する</PrimaryButton>
            </CommentForm>
        );
    }
}

export default CommentAdd;
