import React, {Component} from 'react';
import styled from 'styled-components'
import Button from "./Button";

const Article = styled.article`
  text-align: center;

  & h1 {
    margin: 2rem 0;
    font-weight: bold;
    font-size: 1.5rem;
  }
`
const Section = styled.section`
  line-height: 2rem;
  margin: 2rem 0;

  & h2 {
    font-weight: bold;
  }
`


const NotFound = () => {
    const goHome = () => {
        window.location.href = '/'
    }
    return (
        <Article>
            <h1>404 Error</h1>

            <Section>
                <h2>お探しのページは見つかりませんでした。</h2>
                <p>
                    指定されたページは、削除されたか、他の場所に移動した可能性があります。
                </p>
            </Section>

            <Button primary onClick={goHome}>ホームに戻る</Button>
        </Article>
    )
}

export default NotFound