const Config = () => {
    return {
        OIDC_PATIENT_URL: process.env.REACT_APP_OIDC_PATIENT_URL,
    }
}

export const LoginURL = Config().OIDC_PATIENT_URL + "/"
export const ProfileURL = Config().OIDC_PATIENT_URL + "/profile_inline.html"
export const InitialProfileURL = Config().OIDC_PATIENT_URL + "/profile.html"
export const InitialProfileforiosURL = Config().OIDC_PATIENT_URL + "/profile_inline_ios_init.html"
export const AccountsURL = Config().OIDC_PATIENT_URL + "/settings.html"

export default Config()