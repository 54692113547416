import React, { Component } from 'react';
import styles from '../assets/scss/loading.scss'
import MessageIntervention from "../lib/MessageIntervention";
import State from "./State";
import styled from "styled-components";
import ZIndex from "./ZIndex";
import Colors from "./Colors";

const Overlay = styled.div`
  z-index: ${ZIndex.modal};
  background-color: ${Colors.background.overlay};
  position: fixed;
  width: 100%;
  height: 100%;
`

class Loading extends Component {

    componentDidMount() {

        if (State.LoggedIn) {
            let badge_count = 0;

            this.mi = new MessageIntervention();
            this.mi.wlp_get_unread(this.mi._id_token, this.mi._token)
                .then(data => {
                    if (data.count > 0) {
                        badge_count = data.count;
                        document.title += " (" + badge_count + ")";
                    }
                });
        }

    }

    render() {
        return (
            <Overlay id="loading">
                <div id="loadingBackground">
                    <div id="loader">Loading...</div>
                </div>
            </Overlay>
        );
    }
}

export default Loading;
