import React, {Component} from 'react';
import styled from "styled-components";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

import Colors from "../common/Colors";
import {Table, Tr, Th, Td,} from "../common/Table"
import Day from "../common/Day";
import UnreadIcon from "../common/UnreadIcon";

const Subject = styled.p`
  font-weight: bold;
  height: 3rem;
  background-color: inherit;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
`


/**
 * 掲示板一覧
 */
class ThreadList extends Component {

    constructor(p) {
        super(p);
        dayjs.extend(customParseFormat)
    }

    selectTopic(facility_code, tid) {
        window.location.href = "/topics/" + facility_code + "/" + tid;
    }

    render() {
        const list = this.props.list;
        const threads = list.threads || []

        return (
            <Table>
                <Tr>
                    <Th width={'15%'}>コメント状況</Th>
                    <Th width={'10%'}>管理番号</Th>
                    <Th width={'15%'}>公開元</Th>
                    <Th width={'45%'}>タイトル</Th>
                    <Th width={'15%'}>最終更新日</Th>
                </Tr>
                {threads.map(t =>
                    (<Tr key={t.tid} onClick={() => this.selectTopic(t.company.facility_code, t.tid)} clickable={true}>
                        <Td><UnreadIcon isRead={!t.unread_count && t.Opened}/></Td>
                        <Td>{t.vid}</Td>
                        <Td>{t.company.facility_name}</Td>
                        <Td><Subject>{t.subject}</Subject></Td>
                        <Td><Day parse={'YYYYMMDDTHHmmssZ'} format={'YYYY/MM/DD'}>{t.update_date}</Day></Td>
                    </Tr>)
                )}
            </Table>
        );
    }
}

export default ThreadList;
