import React, {Component} from 'react';


/**
 * お問合わせ画面
 */
class Contact extends Component {

    render() {
        return (
            <h2 className="Title">お問合わせ</h2>
        );
    }

}


export default Contact;
