import React, { Component } from 'react';
import styled from 'styled-components';
import Colors from "../common/Colors";

import Pen from "../assets/images/pen.svg"
import Trash from "../assets/images/trash.svg"


const Base = styled.button`
  width: ${props => {
    switch (props.size){
      case('large'):
          return "240px"
      case('medium'):
          return "176px"
      case('small'):
          return "96px"
      default:
          return props.width? props.width : "240px"
    }
  }};
  height: ${props => {
    switch (props.size){
      case('large'):
        return "56px"
      case('medium'):
        return "56px"
      case('small'):
        return "40px"
      default:
        return props.height? props.height : "56px"
    }
  }};
  border-radius: 27px;
  font-size: 1rem;
  border:none;
  cursor: ${props => props.disabled? 'not-allowed' : 'pointer'};
  font-weight: bold;
  margin: 0 5px;
  padding: 1px;
`

const Primary = styled(Base)`
  background-color: ${props => props.disabled? Colors.Ashgray : Colors.button.background.primary};
  border-color: ${props => props.disabled? Colors.Ashgray : Colors.button.border.primary};
  color: ${props => props.disabled? Colors.White : Colors.button.text.primary};
`

const Secondary = styled(Base)`
  background-color: ${Colors.button.background.secondary};
  border: 2px solid ${Colors.button.border.secondary};
  color: ${Colors.button.text.secondary}
`

const Tertiary = styled.button`
  width: 96px;
  height: 40px;
  background-color: ${Colors.button.background.tertiary};
  border: 1px solid ${Colors.button.border.tertiary};
  color: ${Colors.button.text.tertiary};
  border-radius: 20px;
  font-size: 1rem;
  padding: 0;
  cursor: pointer;
  
  img {
    vertical-align: -1px;
    padding-right: 4px;
  }
`

const Img = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 2px;
`

export class PrimaryButton extends Component{
    render() {
        return (
            <Primary {...this.props} />
        );
    }
};

export class SecondaryButton extends Component{
    render() {
        return (
            <Secondary {...this.props} />
        );
    }
};

export class EditButton extends Component {
    render() {
        return (
            <Tertiary {...this.props}>
                <Img src={Pen} />
                {this.props.children}
            </Tertiary>
        )
    }
}

export class TrashButton extends Component {
    render() {
        return (
            <Tertiary {...this.props}>
                <Img src={Trash} />
                {this.props.children}
            </Tertiary>
        )
    }
}

export default class Button extends Component {
    render() {
        if(this.props.primary){
            return (<PrimaryButton {...this.props} />);
        }
        if(this.props.secondary){
            return (<SecondaryButton {...this.props} />);
        }
    }
}