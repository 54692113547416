import React, {Component} from 'react';
import styled from "styled-components";
import Iframe from "react-iframe";
import {ProfileURL,InitialProfileURL,InitialProfileforiosURL} from "../config/Config";
import State from "../common/State"


const FrameContainer = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
`

/**
 * プロフィール画面
 */
class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ios : false,
        }

        let agent = window.navigator.userAgent.toLowerCase();
        if (agent.indexOf("iphone") > 0 || agent.indexOf("ipad") > 0) {
            this.state.ios = true;

        }
    }

    render() {
        return (
            <FrameContainer>
                <Iframe
                    position='relative'
                    width='100%'
                    height='100%'
                    url={ State.IsItialized ? ProfileURL:this.state.ios ? InitialProfileforiosURL : InitialProfileURL}
                />
            </FrameContainer>
        );
    }

}


export default Profile;
