import styled from "styled-components";
import Colors from "../common/Colors";

export const Textarea = styled.textarea`
  border: 1px solid ${Colors.border.primary};
  padding: 0.5rem;
  width: ${props => props.width || "calc(100% - 1rem)"};
  height: ${props => props.height || "10rem"};
  border-radius: 4px;
  line-height: 1rem;
  font-size: 1rem;

  &::placeholder {
    font-size: 1rem;
    color: ${Colors.Gray};
  }
`