import React, {Component} from 'react';
import styled from "styled-components";

import colors from "../common/Colors"


const Title = styled.h2`
  text-align: center;
  color: ${colors.primary};
  font-size: 1.4rem;
  font-weight: normal;
`

const Img = styled.img`
  width: 2.4rem;
`

const P = styled.p`

`

export default class PageTitle extends Component {
    render() {
        return (
            <Title>
                <Img src={this.props.src}/>
                <P>{this.props.children}</P>
            </Title>
        );
    }
}