export default {
    Orange: "#FF5C00",
    Darkorange: "#E37E22",
    Red: "#D92B00",
    Dimgray: "#464646",
    Gray: "#888888",
    Ashgray: "#CACACC",
    Lightgrey: "#F0F0F0",
    White: "#FFFFFF",
    TransBlack: "rgba(0,0,0,0.5)",
    TransLightBlack: "rgba(0,0,0,0.2)",
    TransparentYellow: "#FFDA4626",
    TranslucentYellow: "#FFDA4680",

    primary: "#FF5C00",
    secondary: "#888888",
    text: {
        primary: "#464646",
        secondary: "#888888",
        white: "#FFFFFF",
        alert: "#D92B00",
    },
    background: {
        primary: "#FFFFFF",
        secondary: "#F0F0F0",
        tertiary: "#E37E22",
        overlay: "rgba(0,0,0,0.5)",
    },
    border: {
        primary: "#888888",
        secondary: "#CACACC",
        tertiary: "#F0F0F0",
    },
    list: {
        background: {
            odd: "#FFFFFF",
            even: "#FFDA4626",
            hover: "#FFDA4680",
        }
    },
    button: {
        background: {
            primary: "#E37E22",
            secondary: "#FFFFFF",
            disabled: "#CACACC",
            tertiary: "#FFFFFF",
        },
        border: {
            primary: "#E37E22",
            secondary: "#E37E22",
            disabled: "#CACACC",
            tertiary: "#888888",
        },
        text: {
            primary: "#FFFFFF",
            secondary: "#E37E22",
            disabled: "#FFFFFF",
            tertiary: "#464646",
        }
    },
}
