import React, {Component} from 'react';

import Modal from "../common/Modal";
import styled from "styled-components";

import AccountImg from "../assets/images/account.png"
import AccountGoldImg from "../assets/images/account_gold.png"
import ClockLight from "../assets/images/clock_light.svg";

import Button, {EditButton, TrashButton} from "../common/Button";
import Day from "../common/Day";
import MessageIntervention from "../lib/MessageIntervention";
import Colors from "../common/Colors";
import {Textarea} from "../form/Textarea";

const Form = styled.form`
  .commentHead {
    margin-bottom: 17px;

    .contributor {

      margin-bottom: 0.5rem;

      img {
        vertical-align: top;
        margin-right: 0.5rem;
      }

      time {
        margin-top: 8px;
        font-size: .8rem;
        color: ${Colors.text.secondary};
      }
    }

    & button:nth-child(1) {
      margin-right: 0.5rem;
    }
  }
`

const CommentModButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:nth-child(1) {
    margin-right: 0.5rem;
  }
`
const CommentMessage = styled.p`
  overflow-wrap: break-word;
`

const CommentButtonsArea = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  & > button:nth-child(1) {
    margin-right: 0.5rem;
  }
`

const Img24 = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.2rem;
`


/**
 * コメント編集/キャンセル/削除
 */
class CommentMod extends Component {

    constructor(props) {
        super(props);
        this.state = {
            input: props.comment.message,
            editing: false,
            deleting: false,
            showModal: false,
        };

        this.mi = new MessageIntervention();

        this.handleEditing = this.handleEditing.bind(this);

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleDeleting = this.handleDeleting.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    //コメント更新画面切り替え
    handleEditing(e) {
        e.preventDefault();
        this.setState({
            editing: !this.state.editing,
            input:this.props.comment.message,
        });
        this.props.commentEditing();
    }

    //コメント削除モーダルOPEN
    handleOpenModal(e) {
        e.preventDefault();
        this.setState({showModal: true});
    }

    //コメント削除モーダルCLOSE
    handleCloseModal(e) {
        e.preventDefault();
        this.setState({showModal: false});
    }

    //コメント削除
    handleDeleting(e) {
        e.preventDefault();

        this.mi.wlp_del_comment(
            this.mi._id_token,
            this.mi._token,
            this.mi._facility_code,
            this.props.thread.tid,
            this.props.comment.cid,
            this.props.thread.index, true, this.props.thread.size)
            .then(() => {
                this.setState({showModal: false})
                this.props.onSubmit()
            }).catch(error => console.error(error))

    }

    onCancel = (e) => {
        e.preventDefault();
        this.setState({
            editing: !this.state.editing,
            input:this.props.comment.message,
        });
        this.props.commentEditing();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.props.comment.message && !this.state.input) {
            return;
        }

        this.mi.wlp_mod_comment(
            this.mi._id_token,
            this.mi._token,
            this.mi._facility_code,
            this.props.thread.tid,
            this.props.comment.cid,
            this.state.input || this.props.comment.message,
            this.props.thread.index, false, this.props.thread.size)
            .then(data => {
                let pattern = "文字数が上限を超えています";
                if (data.message && data.message.indexOf(pattern) > -1) {
                    console.error(
                        data.message,
                        '\n comment length:' + this.state.input.length
                    )
                } else {
                    if (this.state.input) this.props.comment.message = this.state.input;
                    this.setState({
                        message: this.state.input,
                        editing: !this.state.editing,
                    })
                    this.props.commentEditing();
                }
            })
            .catch(error => console.error(error))
    }

    render() {
        const {editing} = this.state;
        const {deleting} = this.state;

        return (
            <div>
                <Form>

                    {editing && (
                        <div>
                            <Textarea
                                maxLength="2000"
                                onChange={(e) => {
                                    this.setState({input: e.target.value})
                                }}
                                placeholder={this.state.input}
                                value={this.state.input}
                                height={"5rem"}
                            />
                            <CommentModButtons>
                                <Button secondary size={'medium'} onClick={this.onCancel}>キャンセル</Button>
                                <Button primary onClick={this.handleSubmit}>コメントを更新する</Button>
                            </CommentModButtons>
                        </div>
                    )}
                    {!editing && !deleting && (
                        <div>
                            <p className="commentHead">
                                <div className="contributor">
                                    {/*TODOOOOOOOOOOOOOO 画像ぼやけてるので48×2サイズで書き出した方がいいよ*/}
                                    <Img24 src={this.props.comment.IsDoctorComment ? AccountImg : AccountGoldImg}/>
                                    {this.props.comment.IsDoctorComment ?
                                        //<> {this.props.comment.doctor_name} ({this.props.comment.doctor_occupation})</>
                                        <> {this.props.comment.doctor_name}</>
                                        :
                                        <>{this.props.comment.patient_name}</>
                                    }
                                </div>
                                <div className="contributor">
                                    <Img24 src={ClockLight}/>
                                    <Day parse={'YYYYMMDDTHHmmssZ'}
                                         format={'YYYY/MM/DD HH:mm'}>{this.props.comment.update_date}</Day>
                                </div>
                            </p>
                            <CommentMessage>{this.props.comment.message}</CommentMessage>

                            {this.props.function_status && this.props.comment.editable ?
                                <CommentButtonsArea>
                                    <EditButton type="button" onClick={this.handleEditing}>編集</EditButton>

                                    <TrashButton type="button" onClick={this.handleOpenModal}>削除</TrashButton>
                                    <Modal
                                        appElement={document.getElementById('root')}

                                        isOpen={this.state.showModal}
                                        contentLabel="コメントの削除確認"
                                        onRequestClose={this.handleCloseModal}
                                        align={"left"}
                                    >
                                        <h1>コメントの削除確認</h1>
                                        <p>
                                            コメントを削除してよろしいですか？ <br/>
                                            削除後は元に戻すことはできません。
                                        </p>
                                        <p>
                                            <Button secondary
                                                    type="button"
                                                    onClick={this.handleCloseModal}
                                                    size={"medium"}
                                            >キャンセル</Button>
                                            <Button primary
                                                    type="button"
                                                    onClick={this.handleDeleting}
                                                    size={"medium"}
                                            >削除</Button>

                                        </p>
                                    </Modal>
                                </CommentButtonsArea>
                                :
                                <></>
                            }
                            <hr color={Colors.border.secondary}/>
                        </div>
                    )}
                </Form>
            </div>
        );
    }
}

export default CommentMod;
