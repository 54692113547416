import React, {Component} from 'react'
import styled from 'styled-components';
import {useLocation} from 'react-router-dom'
import Colors from "./Colors";
import ZIndex from "./ZIndex";
import { ProfileURL } from "../config/Config";
import State from "./State";

const Nav = styled.nav`
  display: flex;
  align-items: center;
`


const Ul = styled.ul`
  height: 100%;
  display: flex;
  align-items: start;
  color: ${Colors.text.secondary};
  font-size: 1rem;
  margin: 0;
  padding: 0;

  & > li:last-child > div {
    border-right: none;
  }
`

const Li = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &:hover > div {
    color: ${Colors.primary};
  }

  &::after {
    width: 4rem;
    height: 6px;
    content: "";
    background-color: ${props => props.href == useLocation().pathname ? Colors.primary : Colors.White};
  }
`
const Link = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.5rem 0 0.5rem;
  color: ${Colors.text.secondary};
  text-decoration: none;
  padding: 0 1rem;
  border-right: 1px solid ${Colors.border.primary};
`

const NavDrop = styled(Li)`
  position: relative;
  cursor: default;

  &:hover > div {
    color: ${Colors.Gray};
  }

  &::after {
    width: 4rem;
    height: 6px;
    content: "";
    background-color: ${props => props.children[1].props.children.some(c => c.props.href == useLocation().pathname) ? Colors.primary : Colors.White};
  }

  & > div:first-child {
    position: relative;
    padding-right: 2rem;

    &::after {
      position: absolute;
      top: 0.2rem;
      right: 1rem;
      padding-left: 0.2rem;
      font-size: 0.8rem;
      content: "\u25BC";
    }
  }
`

const NavDropMenu = styled.div`
  display: none;
  width: 10rem;
  text-align: left;
  padding: 1rem;
  background-color: ${Colors.background.primary};
  position: absolute;
  top: 3.5rem;
  left: 0;
  box-shadow: 0 0 1rem ${Colors.TransLightBlack};
  z-index: ${ZIndex.navDrop};
  border-radius: 4px;

  ${NavDrop}:hover & {
    display: block;
  }
`

const NavDropLink = styled(Link)`
  display: block;
  border-right: none;
  cursor: pointer;
  position: relative;
  margin-top: 1rem;

  &:hover {
    color: ${Colors.Orange};
  }

  &::after {
    position: absolute;
    bottom: -6px;
    left: 1rem;
    width: 4rem;
    height: 6px;
    content: "";
    background-color: ${props => props.href == useLocation().pathname ? Colors.primary : Colors.White};
  }
`

const NavDropLink2 = styled(NavDropLink)`
  display: ${props => props.visible ? 'flex' : 'none'};
`

const Li2 = styled(Li)`
  display: ${props => props.visible ? 'flex' : 'none'};
`


export default class Navbar extends Component {
    render() {
        return (
            <Nav><Ul>
                <Li2 visible={State.IsItialized} href="/topics" onClick={this.gotoPage}><Link>ホーム</Link></Li2>
                <NavDrop>
                    <Link>マイページ</Link>
                    <NavDropMenu>
                        <NavDropLink2 visible={State.IsItialized} href="/profile" onClick={this.gotoPage}>プロフィール</NavDropLink2>
                        <NavDropLink href="/logout" onClick={this.gotoPage}>ログアウト</NavDropLink>
                    </NavDropMenu>
                </NavDrop>
                <NavDrop>
                    <Link>ヘルプ</Link>
                    <NavDropMenu>
                        <NavDropLink href="https://www.youtube.com/watch?v=qe70mr_I4Bc" target="_blank"
                                     onClick={this.gotoPage}>使い方 (動画)</NavDropLink>
                        <NavDropLink href={"dummy"}/>
                        {/*<NavDropLink href="https://www.youtube.com/watch?v=gboTdMTQpdA" target="_blank" onClick={this.gotoPage}>使い方2 (動画)</NavDropLink>*/}
                        {/*<NavDropLink href="https://www.youtube.com/watch?v=gboTdMTQpdA" target="_blank" onClick={this.gotoPage}>使い方3 (動画)</NavDropLink>*/}
                    </NavDropMenu>
                </NavDrop>
                <Li href="https://welby.jp/contact/app" target="_blank" onClick={this.gotoPage}><Link>お問合わせ</Link></Li>
            </Ul></Nav>
        )
    }

    gotoPage = (e) => {
        e.preventDefault();
        let target = e.currentTarget.getAttribute('target');
        if (target) {
            open(e.currentTarget.getAttribute('href'), target)
        } else {
            location.href = e.currentTarget.getAttribute('href');
        }
    }

}