import React, {Component} from 'react';
import styled from "styled-components";

import Colors from "../common/Colors"

const Title = styled.h3`
  margin: 1rem 0;

  &:before {
    display: inline-block;
    background-color: ${Colors.primary};
    height: 2rem;
    width: 8px;
    border-radius: 4px;
    margin-right: 0.5rem;
    content: "";
    vertical-align: middle;
  }
`

export default class SubTitle extends Component {
    render() {
        return (
            <Title>
                {this.props.children}
            </Title>
        );
    }
}