import React, {Component} from 'react';
import styled from 'styled-components';

import MessageIntervention from "../../lib/MessageIntervention";

import ThreadList from "../../thread/List";
import ThreadListSmall from "../../thread/ListSmall";
import PageTitle from "../../common/PageTitle"

import BbsIcon from "../../assets/images/bbs.svg"
import SubTitle from "../../common/SubTitle";
import {Select} from "../../form/Select";
import {Text} from "../../form/Text";
import {PrimaryButton} from "../../common/Button";
import Colors from "../../common/Colors";
import MediaQuery, {LargeScreen, SmallScreen} from "../../common/MediaQuery";
import Pagination from "../../common/Pagination";
import {RestrictionMessage} from "../../setting/Setting";

const Section = styled.section`
  margin-bottom: 5rem;
`

const ThreadSubTitle = styled.span`
  margin-right: 0.5rem;

  & > b {
    font-weight: bold;
  }
`

const Content = styled.div`
  background-color: ${Colors.background.secondary};
  padding: 1rem;
`

const FormItems = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem;

  @media (max-width: ${MediaQuery.largeScreen.width}) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

`

const Item = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  max-width: ${props => props.maxWidth || "100%"};
  width: ${props => props.width || null};
  margin: ${props => props.margin || null};

  & > label {
    margin-bottom: 1rem;
  }
`

const Alert = styled.div`
  text-align: center;
  color: ${Colors.text.alert};
`

/**
 * ホーム画面
 */
export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            facility: 0,
            word: '',

            contents: JSON.parse(localStorage.getItem("search_param")) || {},
            err_message: '',
            list: {
                index: 0,
                size: 0,
                length: 0,
                unread_count: 0,
                descending: true,
                patient_editable: false,
                threads: []
            },
            facility_list: [],
        };
        this.mi = new MessageIntervention();
        this.getFacility();

        //@TODO localstorageの検索結果取得
        // if (localStorage.getItem("search_result")) {
        //     this.state.list = JSON.parse(localStorage.getItem("search_result"));
        // }
        // else {
        this.mi.wlp_list_threadV2(this.mi._id_token, this.mi._token, 0, this.state.word, this.state.status, 0, true, 20)
            .then(list => this.setState({list: list}))
            .catch(error => console.error(error))
        //}

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    getFacility() {
        this.mi.wlp_facility_list(this.mi._id_token, this.mi._token)
            .then(list => {this.setState({facility_list: list.data})})
            .catch(error => console.error(error))
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let status = this.state.status;
        let facility = this.state.facility;
        let word = this.state.word;

        if (word.length == 1) {
            this.setState({err_message: 'キーワードは2文字以上入力してください',})
        } else {
            this.mi.wlp_search_threadV2(this.mi._id_token, this.mi._token, facility, word, true, status, 20)
                .then(list => {
                    this.setState({
                        list: list,
                        err_message: '',
                        contents: {"status": status, "facility": facility, "word": word},
                    })
                    //localStorage.setItem('search_result', JSON.stringify(data))
                    localStorage.setItem('search_param', JSON.stringify(this.state.contents))
                    localStorage.setItem('facility_code', facility)
                })
                .catch(err => console.error(err))
        }
    }


    render() {
        const {list} = this.state
        const total = list.length || 0
        const threads = list.threads || []
        const index = list.index || 0

        const facilities = this.state.facility_list || [];
        let facility_data = {};

        Object.keys(facilities).map(key => {
            if (facilities[key].id == this.state.facility ||
                ( this.state.facility == 0 && facilities[key].patient_commentable === false)
            ) {
                facility_data = facilities[key];
            }
        })

        return (
            <article className="App">
                <PageTitle src={BbsIcon}>掲示板一覧</PageTitle>

                <Section>

                    <RestrictionMessage fnc={facility_data} default_show_modal={false}/>

                    <SubTitle>
                        絞り込み検索
                    </SubTitle>

                    <Content>
                        <form onSubmit={this.handleSubmit}>
                            <Alert>{this.state.err_message}</Alert>

                            <FormItems>
                                <Item>
                                    <label>コメント状況</label>
                                    <Select name="status" onChange={this.handleChange}>
                                        <option value="0">すべて</option>
                                        <option value="1">未読あり</option>
                                        <option value="2">未読なし</option>
                                    </Select>
                                </Item>

                                <Item>
                                    <label>医療機関名</label>
                                    <Select name="facility" onChange={this.handleChange}>
                                        <option value="0">{"全て"}</option>
                                        {
                                            Object.keys(facilities).map(key => (
                                                <option
                                                    key={facilities[key].id}
                                                    value={facilities[key].id}
                                                    onChange={() => {
                                                        this.setState({facility: facilities[key].id})
                                                    }}
                                                >
                                                    {facilities[key].company_name}
                                                </option>
                                            ))
                                        }
                                    </Select>
                                </Item>

                                <Item>
                                    <label>キーワード</label>
                                    <Text name="word"
                                          value={this.state.word}
                                          onChange={this.handleChange}
                                          placeholder={"2文字以上"}
                                    />
                                </Item>
                            </FormItems>
                            <FormItems>
                                <PrimaryButton type="submit">検索する</PrimaryButton>
                            </FormItems>
                        </form>
                    </Content>

                </Section>

                <Section>
                    <SubTitle>
                        <ThreadSubTitle><b>{total}</b>件中</ThreadSubTitle>
                        <ThreadSubTitle><b>{!!threads.length ? (index + 1) + "〜" : ""}{index + threads.length}</b>件目</ThreadSubTitle>
                    </SubTitle>
                    <div className="Thread">
                        <LargeScreen>
                            <ThreadList list={list}/>
                        </LargeScreen>
                        <SmallScreen>
                            <ThreadListSmall list={list}/>
                        </SmallScreen>
                    </div>
                </Section>
                <Pagination totalRecords={total} pageLimit={20} pageNeighbours={1} onPageChanged={this.onPageChanged}/>
            </article>
        );
    }

    onPageChanged = paginationData => {
        this.mi.wlp_list_threadV2(
            this.mi._id_token,
            this.mi._token,
            0, this.state.word, this.state.status,
            (paginationData.currentPage - 1) * paginationData.pageLimit,
            true, 20)
            .then(list => this.setState({list: list}))
            .catch(error => console.error(error))
    }
}

