import React, {Component} from 'react';
import ThreadMod from "./Mod";
import MessageIntervention from "../lib/MessageIntervention";


/**
 * 掲示板画面
 */
class Detail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            thread: {
                facility_code: "",
                facility_name: "",
                index: 0,
                size: 0,
                length: 0,
                descending: true,
                tid: "",
                doctor_sub: "",
                patient_sub: "",
                subject: "",
                message: "",
                create_date: "",
                update_date: "",
                Opened: false,
                visible: true,
                deleted: false,
                patient_commentable:true,
                comments: []
            }
        };

        this.mi = new MessageIntervention();
        let facility = parseInt(this.props.match.params.facility_code, 10);

        this.mi.wlp_detail_thread(
            this.mi._id_token,
            this.mi._token,
            facility,
            this.props.match.params.tid, 0, true, 0)
            .then(
                data => {
                    this.setState({thread: data})
                    localStorage.setItem('facility_code', facility)
                })
            .catch(error => {
                window.location.href = "/notfound";
            });
    }


    render() {
        return (
            <ThreadMod thread={this.state.thread}/>
        );
    }

}


export default Detail;
