import React, {Component} from "react";
import styled from "styled-components"
import PageTitle from "../common/PageTitle";
import SubTitle from "../common/SubTitle";
import CogImage from "../assets/images/cog.svg"
import Colors from "../common/Colors";
import Button, {PrimaryButton, SecondaryButton} from "../common/Button";
import {Radio, Label} from "../form/Radio"
import MessageIntervention from "../lib/MessageIntervention";
import Modal from "../common/Modal";

const FormItem = styled.div`
  display: flex;
  background-color: ${Colors.background.secondary};
  padding: 2rem 4rem;
  margin: 1rem 0 2rem;

  & label {
    margin-right: 1rem;
  }
`

const FormButton = styled.div`
  display: flex;
  justify-content: center;

  & button {
    margin: 0 1rem;
  }
`

const RestrictText = styled.p`
  padding: 2rem 0rem 0;
  //text-align: center;
`

export default class Setting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enabled: true,
        }
    }

    render() {
        const goHome = () => {
            window.location.href = '/'
        }

        return (
            <article>
                <PageTitle src={CogImage}>設定</PageTitle>
                <section>
                    <SubTitle>掲示板の利用設定</SubTitle>
                    <p>掲示板機能を利用しない場合は、ご自身を含む全利用者（患者様、医療スタッフ）が掲示板の新規作成およびコメント投稿ができなくなります。</p>
                    <form onSubmit={this.handleSubmit}>
                        <FormItem>
                            <Radio id="enable"
                                   name="enabled"
                                   onChange={() => {
                                       this.setState({enabled: !this.state.enabled})
                                   }}
                                   checked={this.state.enabled === true}/>
                            <Label htmlFor="enable">利用する</Label>
                            <Radio id="disable"
                                   name="enabled"
                                   onChange={() => {
                                       this.setState({enabled: !this.state.enabled})
                                   }}
                                   checked={this.state.enabled === false}/>
                            <Label htmlFor="disable">利用しない</Label>
                        </FormItem>
                        <FormButton>
                            <SecondaryButton onClick={goHome} type={"reset"}>キャンセル</SecondaryButton>
                            <PrimaryButton type={"submit"}>変更する</PrimaryButton>
                        </FormButton>
                    </form>
                </section>
            </article>
        )
    }
}

export class RestrictionMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            visible: true,  //利用制限ダイアログの表示(true)・非表示(false)
        }

        this.mi = new MessageIntervention();
        this.mi.wlp_get_usagelimit_visible(this.mi._id_token, this.mi._token)
            .then(data => {
                if (data.usage_limit === true) {
                    this.setState({showModal: true});
                } else {
                    this.setState({showModal: false})
                }
            })
            .catch(error => console.error(error))

        this.handleSubmit = this.handleSubmit.bind(this);

        //this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.showModal) {
            this.mi.wlp_set_usagelimit_visible(this.mi._id_token, this.mi._token, this.state.visible)
                .then(data => {
                    if (data.message == "success") {
                        this.setState({
                            visible: !this.state.visible,
                            showModal: !this.state.showModal,
                        })
                    }
                })
                .catch(error => console.error(error))
        }

    }

    //掲示板削除モーダルCLOSE
    handleCloseModal(e) {
        e.preventDefault();
        this.setState({showModal: false});
    }

    render() {
        const fnc = this.props.fnc;
        const show_text = this.props.show_text;
        const default_show_modal = this.props.default_show_modal;

        return (Object.keys(fnc).length && !fnc.patient_commentable) ?
            <form>
                {
                    show_text ??
                    <RestrictText>
                        一部機能の利用が制限されています。<br/>
                        過去の掲示板を閲覧することはできますが、コメントの追加・編集・削除はできません。
                    </RestrictText>
                }

                {
                    default_show_modal ??
                    <Modal
                        isOpen={this.state.showModal}
                        onRequestClose={this.handleCloseModal}
                        align={"left"}
                    >
                        <h1>利用制限のご案内</h1>
                        <p>
                            一部機能の利用が制限されています。<br/>
                            過去の掲示板を閲覧することはできますが、コメントの追加・編集・削除はできません。
                        </p>

                        <div>
                            <label>次回から表示しない</label>
                            <input
                                name="usagelimit"
                                type="checkbox"
                                checked={!this.state.visible}
                                onChange={() => {
                                    this.setState({visible: !this.state.visible})
                                }}
                            />
                        </div>

                        <Button primary type="submit" onClick={this.handleSubmit} size={"medium"}>OK</Button>

                    </Modal>
                }

            </form>
            :
            <></>
    }
}