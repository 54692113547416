import ReactModal from 'react-modal'
import styled from 'styled-components'
import Colors from "./Colors";
import React, {Component} from "react";
import x from "../assets/images/x.svg"

ReactModal.setAppElement('#root')

const RM = styled(ReactModal)`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: ${Colors.background.primary};
  position: fixed;
  //min-width: 25rem;
  text-align: center;
  padding: 0 0 3rem;
  width: 90%;

  & > h1 {
    font-size: 1.2rem;
    font-weight: bold;
    background-color: ${Colors.background.secondary};
    padding: 0.9rem 0.5rem;
  }

  & > p {
    display: flex;
    padding: 2rem 2rem 0;
    justify-content: space-evenly;
    text-align: ${props => props.align == 'left' ? 'left' : 'center'};
  }

  & > div {
    padding: 2rem 0rem 2rem;
    justify-content: space-evenly;
    margin: 0;
    min-width: 25rem;
  }
`

const ModalStyle = {
    overlay: {
        backgroundColor: Colors.background.overlay
    }
}

const Close = styled.button.attrs(props => ({
    type: "button",
}))`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 3rem;
  height: 3rem;
  border: none;
  background-position: center;
  background-color: transparent;
  background-image: url(${x});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
`

export default class Modal extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <RM {...this.props} style={ModalStyle}>
                <Close
                    onClick={(e) => this.props.onRequestClose(e)}
                />
                {this.props.children}
            </RM>
        )
    }
}