import React, {Component} from 'react';
import CommentMod from "./Mod";
import styled from 'styled-components';

const CommentDiv = styled.div`
  margin-bottom: 1rem;

  & hr {
    margin-top: 1rem;
  }
`

const Head = styled.div`
  display: flex;
`

/**
 * コメント一覧
 */
class CommentList extends Component {
    // 書き込みリスト
    render() {
        const commentEditing = this.props.commentEditing;
        const onSubmit = this.props.onSubmit;
        const comments = this.props.comments;
        const thread = this.props.thread;
        const function_status = this.props.function_status

        return (
            <ul>
                {comments.map((c, i) =>
                    (<li key={i}>
                        <Comment
                            commentEditing={commentEditing}
                            onSubmit={onSubmit}
                            thread={thread}
                            comment={c}
                            function_status={function_status}
                        />
                    </li>)
                )}
            </ul>
        );
    }
}


/**
 * コメント1件
 */
class Comment extends Component {

    render() {
        return (
            <CommentDiv>
                <CommentMod
                    commentEditing={this.props.commentEditing}
                    onSubmit={this.props.onSubmit}
                    thread={this.props.thread}
                    comment={this.props.comment}
                    function_status={this.props.function_status}
                />
            </CommentDiv>
        );
    }
}

export default CommentList;
