import React from 'react'
import {useMediaQuery} from 'react-responsive'

export const isLargeScreen = (callBack) => {
    let isLarge = useMediaQuery({minWidth: 1025}) || false
    if (typeof (callBack) == 'function') {
        callBack(isLarge)
    }
    return isLarge
}
export const LargeScreen = ({children}) => {
    return isLargeScreen() ? children : null
}

export const isSmallScreen = (callBack) => {
    let isSmall = useMediaQuery({maxWidth: 1024}) || false
    if (typeof (callBack) == 'function') {
        callBack(isSmall)
    }
    return isSmall
}
export const SmallScreen = ({children}) => {
    return isSmallScreen() ? children : null
}

export default {
    largeScreen: {
        width: '1000px',
    },
}
