import styled from "styled-components";
import Colors from "../common/Colors";

export const Radio = styled.input.attrs({
    type: "radio",
})`
  display: none;

  &:checked + label:before {
    border-color: ${Colors.primary};
  }

  &:checked + label:after {
    opacity: 1;
    transition: opacity 0.25s;
  }
`

export const Label = styled.label`
  position: relative;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:before, :after {
    position: absolute;
    content: "";
    border-radius: 100%;
    background: ${Colors.background.primary};
  }

  &:before {
    left: 0;
    width: 1rem;
    height: 1rem;
    border: 2px solid ${Colors.border.primary};
  }

  &:after {
    left: 0.325rem;
    width: 0.6rem;
    height: 0.6rem;
    background: ${Colors.primary};
    opacity: 0;
  }
`