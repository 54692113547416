import React, { Component } from 'react';
import { BrowserRouter /*HashRouter*/ as Router , Route, Switch, Redirect } from 'react-router-dom'
import './App.css';
import styled, {createGlobalStyle} from "styled-components";
import reset from 'styled-reset'

import Header   from './common/Header';
import Footer   from './common/Footer';

import Home     from './pages/home';
import ProfileSmall  from './profile/ProfileSmall';
import Help     from './help/Help';
import Contact  from './contact/Contact';
import Index from "./pages/index";

import Detail   from './thread/Detail';

import Colors from "./common/Colors";
import MediaQuery from "./common/MediaQuery";
import ZIndex from "./common/ZIndex";
import NotFound from "./common/NotFound";
import PageTop from "./common/PageTop";
import Logout from "./logout/Logout";
import State from "./common/State";
import Loading from "./common/Loading"

const GlobalStyle = createGlobalStyle`
  ${reset}
  html,body {
    margin: 0;
    padding: 0px;
    font: normal normal normal 1rem "Hiragino Sans","Hiragino Kaku Gothic ProN",Meiryo, Helvetica, Arial, sans-serif;
    color: ${Colors.text.primary};
    height: 100%;
    & > div#root{
      height: 100%;
    }
  }

  .ReactModal{
    &Portal,&__Overlay {
      z-index: ${ZIndex.modal};
    }
    &__Overlay {
      background-color: ${Colors.background.secondary};
    }
  }
`

const Wrap = styled.div`
  height: 100%;
`

const Content = styled.main`
  position: relative;
  padding: calc(5rem + 4px) calc((100% - ${MediaQuery.largeScreen.width})/2) 4rem;
  width: ${MediaQuery.largeScreen.width};
  margin: 0;
  min-height: calc(100% - 12rem - 4px);

  @media (max-width: ${MediaQuery.largeScreen.width}) {
    width: calc(100% - 2rem);
    padding: calc(5rem + 4px) 1rem;
  }
`

const FrameContent = styled.main`
  position: relative;
  padding: calc(5rem + 4px) 0 0;
  height: calc(100% - 8rem - 4px);
  width: 100%;
  margin: 0;
  min-height: calc(100% - 8rem - 4px);

  @media (max-width: ${MediaQuery.largeScreen.width}) {
    width: calc(100%);
    height: calc(100% - 8rem - 4px);
    padding: calc(5rem + 4px) 0 0;
  }
`

/**
* 全画面
*/
class App extends Component {

    render() {
        const path = location.pathname
        let isFramePage = false;
        if(path === "/profile") isFramePage = true;
        if(State.LoggedIn){
           return isFramePage? (
               <Wrap className="App">
                   <GlobalStyle />
                   <Router style={"height:100%;"}>
                       <Header />
                       <Loading/>
                       <Switch>
                           <FrameContent className="main">
                               <Route path='/profile' component={ProfileSmall} />
                           </FrameContent>
                       </Switch>
                       <Footer />
                   </Router>
               </Wrap>
           ) : (
               <Wrap className="App">
                   <GlobalStyle />
                   <Router style={"height:100%;"}>
                       <Header />
                       <Loading/>
                       <Content className="main">
                           <Switch>
                               <Route path='/help' component={Help}/>
                               <Route path='/contact' component={Contact}/>
                               <Route exact path='/topics' component={Home}/>
                               <Route path='/topics/:facility_code/:tid' component={Detail}/>
                               <Route path={'/logout'} component={Logout} />
                               <Route exact path={'/'}>
                                   <Redirect to="/topics" />
                               </Route>
                               <Route component={NotFound} />
                           </Switch>
                           <PageTop />
                       </Content>
                       <Footer />
                   </Router>
               </Wrap>
           );
        }else{
            return (
                <Wrap className="App">
                    <GlobalStyle />
                    <Router style={"height:100%;"}>
                        <Header />
                        <Loading/>
                        <FrameContent className="main">
                            <Switch>
                                <Route exact path={'/'} component={Index} />
                                <Redirect to="/" />
                            </Switch>
                        </FrameContent>
                        <Footer />
                    </Router>
                </Wrap>
            );
        }
    }
}

export default App;
