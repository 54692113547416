import {Component} from "react";
import styled from "styled-components";
import Colors from "./Colors";

const Nav = styled.nav`

`

const Ol = styled.ol`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Li = styled.li`
  color: ${props => props.active ? Colors.text.white : Colors.background.tertiary};
  background-color: ${props => props.active ? Colors.background.tertiary : Colors.background.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid ${Colors.background.tertiary};
  border-radius: 50%;
  cursor: pointer;
  visibility: ${props => props.visibility};

  &:hover {
    background-color: ${Colors.background.tertiary};
    color: ${Colors.text.white};
  }
`
const SpanLess = styled.span`
    padding-right: 1px;
    padding-bottom: 1px;
`

const SpanGreater = styled.span`
    padding-left: 1px;
    padding-bottom: 1px;
`

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const PREV_PAGE = 'PREV'
const NEXT_PAGE = 'NEXT'
const FIRST_PAGE = 'FIRST'
const LAST_PAGE = 'LAST'

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

export default class Pagination extends Component {

    constructor(props) {
        super(props);

        const {totalRecords = null, pageLimit = 20, pageNeighbours = 0} = this.props;

        this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 20;

        // pageNeighbours can be: 0, 1 or 2
        this.pageNeighbours = typeof pageNeighbours === 'number'
            ? Math.max(0, Math.min(pageNeighbours, 2))
            : 0;

        const records = typeof totalRecords === 'number' ? totalRecords : 0

        this.state = {
            currentPage: 1,
            totalPages: Math.ceil(records / this.pageLimit),
            totalRecords: records,
        };
    }

    fetchPageNumbers = () => {

        const state = this.state
        state.totalRecords = this.props.totalRecords
        state.totalPages = Math.ceil(this.props.totalRecords / this.pageLimit)

        const totalPages = state.totalPages;
        const currentPage = state.currentPage;
        const pageNeighbours = this.pageNeighbours;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const minNumberBlock = 4;
        const totalNumbers = (this.pageNeighbours * 2) + 2;
        const totalBlocks = (totalNumbers > minNumberBlock) ? totalNumbers + 2: totalNumbers - 1;

        if (totalPages > totalBlocks) {
            //const startPage = Math.max(2, currentPage - pageNeighbours);
            //const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            const startPage = Math.max(1, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 1;
            const hasRightSpill = (totalPages - endPage) > 0;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [PREV_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, NEXT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [PREV_PAGE, ...pages, NEXT_PAGE];
                    break;
                }
            }
            return [FIRST_PAGE, ...pages, LAST_PAGE];
        }

        // const limit = 3;
        // const number = Math.min(Math.max(totalPages, 1), limit);
        return range(1, totalPages);
    }

    render() {
        const {totalRecords} = this.props;
        const totalPages = Math.ceil(totalRecords / this.pageLimit)
        if (!totalRecords || totalPages === 1) return null;

        const pages = this.fetchPageNumbers();
        const currentPage = this.state.currentPage

        return (
            <Nav>
                <Ol>
                    {
                        pages.map((page, idx) => {
                            switch (page) {
                                case(PREV_PAGE):
                                    return (
                                        <Li key={page} onClick={this.handleClick(this.state.currentPage - 1)}><SpanLess>&lt;</SpanLess></Li>)
                                    break;
                                case(NEXT_PAGE):
                                    return (
                                        <Li key={page} onClick={this.handleClick(this.state.currentPage + 1)}><SpanGreater>&gt;</SpanGreater></Li>)
                                    break;
                                case(FIRST_PAGE):
                                    return (<Li key={page}
                                                onClick={this.handleClick(1)}
                                                visibility={currentPage == 1 ? "hidden" : "visible"}><SpanLess>&lt;&lt;</SpanLess></Li>)
                                    break;
                                case(LAST_PAGE):
                                    return (<Li key={page}
                                                onClick={this.handleClick(this.state.totalPages)}
                                                visibility={currentPage == totalPages ? "hidden" : "visible"}><SpanGreater>&gt;&gt;</SpanGreater></Li>)
                                    break;
                                case(LEFT_PAGE):
                                    return (<Li key={page} onClick={this.handleMoveLeft}><SpanLess>&lt;</SpanLess></Li>)
                                    break;
                                case(RIGHT_PAGE):
                                    return (<Li key={page} onClick={this.handleMoveRight}><SpanGreater>&gt;</SpanGreater></Li>)
                                    break;
                                default:
                                    return (<Li key={page} onClick={this.handleClick(page)}
                                                active={page == currentPage}>{page}</Li>)
                            }
                        })
                    }
                </Ol>
            </Nav>
        )
    }


    gotoPage = page => {
        const state = this.state
        const {onPageChanged = f => f, totalRecords} = this.props;
        const totalPages = Math.ceil(totalRecords / this.pageLimit)
        const currentPage = Math.max(0, Math.min(page, totalPages));
        const paginationData = {
            currentPage: currentPage,
            totalPages: totalPages,
            pageLimit: this.pageLimit,
            totalRecords: totalRecords
        };

        state.currentPage = currentPage
        state.totalPages = totalPages
        state.totalRecords = totalRecords
        this.setState(state, () => onPageChanged(paginationData));

    }

    handleClick = page => evt => {
        evt.preventDefault();
        if (page < 1) page = 1;
        if (this.state.totalPages < page) page = this.state.totalPages;
        this.gotoPage(page);
    }

    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - (this.pageNeighbours * 2) - 1);
    }

    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + (this.pageNeighbours * 2) + 1);
    }
}