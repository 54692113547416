import React, {Component} from "react";
import Colors from "./Colors";
import styled from "styled-components";

const Unread = styled.div`
  display: flex;
  align-items: center;
  visibility: ${props => props.visibility || "visible"};

  &:after {
    padding-left: 0.5rem;
    color: ${props => props.isRead ? Colors.Gray : Colors.Red};
    content: "${props => props.isRead ? props.readText : props.unreadText}";
  }
`

export default class UnreadIcon extends Component {

    constructor(props) {
        super(props);
        this.unreadText = this.props.unreadText || "未読あり"
        this.readText = this.props.readText || "未読なし"
        this.state = {
            isRead: !!this.props.isRead,
            visibility: this.props.visibility || "visible",
        }
    }

    render() {
        return (
            <Unread isRead={this.state.isRead}
                    visibility={this.state.visibility}
                    unreadText={this.unreadText}
                    readText={this.readText}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26">
                    <defs>
                        <style>{".a{fill:none;}.b{fill:" + Colors.Gray + ";}.c{fill:" + Colors.Red + ";}"}</style>
                    </defs>
                    <g transform="translate(-528.736 -265.197)">
                        <rect className="a" width="30" height="26" transform="translate(528.736 265.197)"/>
                        <path className={this.props.isRead ? "b" : "c"}
                              d="M14.632,32C6.55,32,0,37.321,0,43.888a10.552,10.552,0,0,0,3.258,7.47A14.54,14.54,0,0,1,.126,56.834a.454.454,0,0,0-.086.5.448.448,0,0,0,.417.274,13.071,13.071,0,0,0,8.036-2.938,17.432,17.432,0,0,0,6.139,1.109c8.082,0,14.632-5.321,14.632-11.888S22.714,32,14.632,32ZM7.316,45.717a1.829,1.829,0,1,1,1.829-1.829A1.827,1.827,0,0,1,7.316,45.717Zm7.316,0a1.829,1.829,0,1,1,1.829-1.829A1.827,1.827,0,0,1,14.632,45.717Zm7.316,0a1.829,1.829,0,1,1,1.829-1.829A1.827,1.827,0,0,1,21.948,45.717Z"
                              transform="translate(528.736 233.197)"/>
                    </g>
                </svg>
            </Unread>
        );
    }
}