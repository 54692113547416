import styled from "styled-components";
import Colors from "../common/Colors";


export const Text = styled.input.attrs({
    type: "text",
})`
  border: 1px solid ${Colors.border.primary};
  height: 2rem;
  padding: 0 0.5rem;
  width: ${props => props.width || "15rem"};
  border-radius: 4px;
  line-height: 1rem;
  font-size: 1rem;

  &::placeholder {
    font-size: 1rem;
    color: ${Colors.Gray};
  }
`

