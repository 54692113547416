import React, {Component} from 'react';
import SubTitle from "../common/SubTitle";
import Button, {EditButton, PrimaryButton, SecondaryButton, TrashButton} from "../common/Button";
import Colors from "../common/Colors";
import CommentAdd from "../comment/Add";
import CommentList from "../comment/List";
import styled from "styled-components";
import AccountImage from "../assets/images/account.png";
import Clock from "../assets/images/clock.svg";
import ClockLight from "../assets/images/clock_light.svg";
import TopicIcon from "../assets/images/topic.svg";
import EditIcon from "../assets/images/edit.svg"
import Hospital from "../assets/images/hospital.svg"
import PageTitle from "../common/PageTitle";
import Modal from "../common/Modal";
import MessageIntervention from "../lib/MessageIntervention";
import Day from "../common/Day";
import {Text} from "../form/Text";
import {Textarea} from "../form/Textarea";
import {LargeScreen, SmallScreen} from "../common/MediaQuery";
import {RestrictionMessage} from "../setting/Setting";


const Topic = styled.div`
  border: 4px solid ${Colors.background.secondary};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 4rem;
`

const TopicHead = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;

  & button:nth-child(1) {
    margin-right: 1rem;
  }
`

const TopicFoot = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const TopicSubject = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  overflow-wrap: break-word;
`

const TopicMessage = styled.p`
  margin-bottom: 1rem;
  overflow-wrap: break-word;
`

const Name = styled.span`
  font-weight: bold;
`

const Img24 = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.2rem;
`

const IconLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  margin-right: 1rem;
  margin-top: 0.5rem;
`

const Section = styled.section`
  margin-bottom: 2rem;

  & > p {
    margin-left: 1rem;
  }
`
const FormButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem;

  & button {
    margin: 1rem;
  }
`

/**
 * 掲示板更新/キャンセル/削除
 */
class ThreadMod extends Component {

    constructor(props) {
        super(props);

        this.state = {
            comments: [],
            subject: this.props.thread.subject || '',
            message: this.props.thread.message || '',
            editing: false,
            deleting: false,
            showModal: false,
            comment_editing: false,
            update_comments:false,

            data: {
                facility_code: "",
                facility_name: "",
                index: 0,
                size: 0,
                length: 0,
                descending: true,
                patient_editable: false,
                threadinfo: []
            },
            function: {
                patient_commentable: true
            },
        };

        this.mi = new MessageIntervention();

        this.commentEditing = this.commentEditing.bind(this);
        this.updateCommentList = this.updateCommentList.bind(this);

        this.handleEditing = this.handleEditing.bind(this);
        this.handleDeleting = this.handleDeleting.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    //掲示板更新画面切り替え
    handleEditing(e) {
        e.preventDefault();
        this.setState(this.props.thread)
        this.setState({
            editing: !this.state.editing,
        });
    }

    //掲示板削除モーダルOPEN
    handleOpenModal(e) {
        e.preventDefault();
        this.setState({showModal: true});
    }

    //掲示板削除モーダルCLOSE
    handleCloseModal(e) {
        e.preventDefault();
        this.setState({showModal: false});
    }

    //掲示板削除
    handleDeleting(e) {
        e.preventDefault();

        //@TODO
        this.props.thread.deleted = true;

        this.mi.wlp_del_thread(
            this.mi._id_token,
            this.mi._token,
            this.mi._facility_code,
            this.props.thread.tid,
            this.props.thread.size)
            .then(() => {
                window.location.href = '/'
            })
            .catch(error => console.error(error))
    }

    //掲示板更新キャンセル
    onCancel = (e) => {
        e.preventDefault();
        this.setState({
            editing: !this.state.editing,
        });
    }

    //掲示板更新
    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.state.subject || !this.state.message) return;

        //@TODO
        this.props.thread.subject = this.state.subject;
        this.props.thread.message = this.state.message;

        this.mi.wlp_mod_thread(
            this.mi._id_token,
            this.mi._token,
            this.mi._facility_code,
            this.props.thread.tid,
            this.props.thread.subject,
            this.props.thread.message,
            this.props.thread.size)
            .then(data => this.setState({
                subject: this.state.subject,
                message: this.state.message,
                editing: !this.state.editing,
                data: data
            }))

    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    commentEditing() {
        this.setState({comment_editing: !this.state.comment_editing});
    }

    //コメント一覧(掲示板詳細)更新
    updateCommentList() {

        this.mi.wlp_detail_thread(
            this.mi._id_token,
            this.mi._token,
            this.mi._facility_code,
            this.props.thread.tid, 0, true, 0
        ).then(data => this.setState({
            comments: data.comments,
            update_comments: true,
        })).catch(error => console.error(error))
    }


    render() {
        const thread = this.props.thread;
        const comments = this.state.update_comments ? this.state.comments : thread.comments;

        const {editing} = this.state;
        const {deleting} = this.state;

        const function_data = this.state.function;
        function_data.patient_commentable = thread.patient_commentable;
        const function_status = thread.patient_commentable;

        const for_all = thread.patient_sub == 0 ? true : false;

        let component = editing ? (
            <article className="App">
                <PageTitle src={EditIcon}>掲示板の編集</PageTitle>

                <form onSubmit={this.handleSubmit}>
                    <Section>
                        <SubTitle>管理番号</SubTitle>
                        <p>{thread.vid}</p>
                    </Section>
                    <Section>
                        <TopicHeader editing={editing} deleting={deleting}>{thread.facility_name}</TopicHeader>
                    </Section>

                    <Section>
                        <SubTitle>タイトル</SubTitle>
                        <TopicSubject>
                            <Text name="subject"
                                  maxLength="100"
                                  value={this.state.subject}
                                  placeholder={'件名 (100文字まで)'}
                                  onChange={this.handleChange}
                                  width={"calc(100% - 2rem)"}
                            />
                        </TopicSubject>
                    </Section>

                    <Section>
                        <SubTitle>本文</SubTitle>
                        <TopicMessage>
                            <Textarea name="message"
                                      maxLength="2000"
                                      value={this.state.message}
                                      placeholder={'本文 (2000文字まで)'}
                                      onChange={this.handleChange}
                                      width={"calc(100% - 2rem)"}
                            />
                        </TopicMessage>
                    </Section>
                    <FormButtons>
                        <SecondaryButton onClick={this.onCancel}>キャンセル</SecondaryButton>
                        <PrimaryButton type="submit">掲示板を更新する</PrimaryButton>
                    </FormButtons>
                </form>
            </article>
        ) : (
            <article className="App">
                <PageTitle src={TopicIcon}>掲示板詳細</PageTitle>

                <RestrictionMessage fnc={function_data}/>

                <section>
                    <SubTitle>管理番号: {thread.vid}</SubTitle>
                    {for_all ?
                        <div>※このトピックは医療機関からのお知らせのため返信することはできません。</div>
                        :
                        <></>
                    }
                    <Topic className="ThreadDetail">
                        <TopicHeader
                            edit={this.handleEditing}
                            delete={this.handleOpenModal}

                            showModal={this.state.showModal}
                            closeModal={this.handleCloseModal}
                            execDelete={this.handleDeleting}
                        >
                            {thread.facility_name}
                        </TopicHeader>

                        <TopicSubject>{thread.subject}</TopicSubject>
                        <TopicMessage>{thread.message}</TopicMessage>
                        <hr color={Colors.border.tertiary}/>
                        <TopicFoot>
                            {/*<TopicAuthor>{`${thread.doctor_name}（${thread.doctor_occupation}）`}</TopicAuthor>*/}
                            <TopicAuthor>{`${thread.doctor_name}`}</TopicAuthor>
                            <TopicTime><Day parse={'YYYYMMDDTHHmmssZ'}
                                            format={'YYYY/MM/DD HH:mm'}>{thread.create_date}</Day></TopicTime>
                        </TopicFoot>
                    </Topic>
                </section>

                {function_status && !for_all ?
                    <section>
                        <SubTitle>コメント追加</SubTitle>
                        <CommentAdd
                            onSubmit={this.updateCommentList}
                            commentEditing={this.state.comment_editing}
                            thread={thread}
                        />
                    </section>
                    :
                    <></>
                }

                {!for_all ?
                    <section>
                        <SubTitle>過去のコメント(日付の新しい順)</SubTitle>
                        <div className="CommentList">
                            <CommentList
                                commentEditing={this.commentEditing}
                                onSubmit={this.updateCommentList}
                                thread={thread}
                                comments={comments}
                                function_status={function_status}
                            />
                        </div>
                    </section>
                    :
                    <></>
                }
            </article>
        )

        return component;
    }
}


class TopicHeader extends Component {

    render() {
        let component = this.props.editing ? (
            <div>
                <SubTitle>公開先</SubTitle>
                <p>
                    <IconLabel>
                        <Img24 src={AccountImage}/>
                        <Name>{this.props.children}</Name>
                    </IconLabel>
                </p>
            </div>
        ) : (
            <TopicHead>
                <LargeScreen>
                    <IconLabel>
                        <Img24 src={AccountImage}/>
                        公開先：<Name>{this.props.children}</Name>
                    </IconLabel>
                </LargeScreen>

                <SmallScreen>
                    <Img24 src={Hospital}/>
                    <p>
                        {this.props.children}
                    </p>
                </SmallScreen>
            </TopicHead>
        )

        return component
    }
}

class TopicAuthor extends Component {
    render() {
        return (
            <IconLabel>
                <LargeScreen>
                    <Img24 src={AccountImage}/>
                    作成者：<Name>{this.props.children}</Name>
                </LargeScreen>

                <SmallScreen>
                    <Img24 src={AccountImage}/>
                    ［作成者］<Name>{this.props.children}</Name>
                </SmallScreen>
            </IconLabel>
        )
    }
}

class TopicTime extends Component {
    render() {
        return (
            <IconLabel>
                <LargeScreen>
                    <Img24 src={Clock}/>
                    作成日時：{this.props.children}
                </LargeScreen>

                <SmallScreen>
                    <Img24 src={ClockLight}/>
                    ［作成日時］{this.props.children}
                </SmallScreen>
            </IconLabel>
        )
    }
}


export default ThreadMod;
